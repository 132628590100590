<template>
  <header id="header__home">
    <div id="header__wrap">
      <img src="../../assets/header_logo.png" alt="logo" />
      <p id="header__title">
        【GOLD EXPERIENCE】<br
          class="header_title-br"
        />それは本格的かつ革新的なパワーみなぎる体験
      </p>
    </div>
  </header>
</template>

<script>
export default {};
</script>

<style>
/* -=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=- */
/* header */
/* -=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=- */
#header__home {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: visible;
  z-index: 99;
  background-color: transparent;
  position: absolute;
  width: 100%;
  height: 90px;
  left: 0px;
  top: 0px;
}

#header__home #header__wrap {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  max-width: 1020px;
}

#header__home img {
  width: auto;
  height: 25.63px;
}

#header__home #header__title {
  margin: 0 0 0 15px;
  overflow: visible;
  width: 289px;
  white-space: nowrap;
  line-height: 24px;
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  letter-spacing: 1px;
}

.header_title-br {
  display: none;
}

@media screen and (max-width: 1020px) {
  #header__home #header__wrap {
    padding: 0 24px;
  }
}

@media screen and (max-width: 768px) {
  #header__home img {
    width: 38px;
    height: 24px;
    left: 20px;
    top: 15px;
    overflow: visible;
  }
  #header__home {
    height: 50px;
  }
  #header__home #header__title {
    font-size: 12px;
    line-height: 16px;
  }
  .header_title-br {
    display: block;
  }
}
</style>
